// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-daten-js": () => import("./../../../src/pages/Daten.js" /* webpackChunkName: "component---src-pages-daten-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/Impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mehr-js": () => import("./../../../src/pages/Mehr.js" /* webpackChunkName: "component---src-pages-mehr-js" */),
  "component---src-templates-leistungen-details-js": () => import("./../../../src/templates/leistungen-details.js" /* webpackChunkName: "component---src-templates-leistungen-details-js" */)
}

